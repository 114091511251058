import React from "react";
import "./App.css";

import {BrowserRouter as Router,Switch,Route} from "react-router-dom";

import Home from "./components/Home";
import comingSoon from "./components/opportunities";
import Projects from "./components/project";
import AboutCard from "./components/AboutCard";
import ContactUs from "./components/ContactUs";
import demo from "./components/termsCondition";
import appdownload from "./components/appdownload";
import privacypolicy from "./components/privacypolicy";
import GlobalDashboard from "./components/globalDashboard";

const App = () => {
  return (   
    <Router>
      <Switch>
      <Route path="/" exact component={Home}/>
      <Route path="/projects" component={Projects}/>
      <Route path="/About" component={AboutCard}/>
      <Route path="/ContactUs" component={ContactUs} />
      <Route path="/termsncondtions" component={demo} />
      <Route path="/privacypolicy" component={privacypolicy} />
      <Route path="/appdownload" component={appdownload} />
      <Route path="/Opportunities" component={comingSoon} />
      <Route path="/globalDashboard" component={GlobalDashboard} />
      </Switch>
  </Router>
  );
  };
  export default App;

 

