import React from "react";
import {isMobile} from 'react-device-detect'; 
import {isAndroid} from 'react-device-detect';
import {isIOS} from 'react-device-detect';
const appdownload = () => {
    if (isMobile) {
        if(isIOS){
            window.location.href = 'https://apps.apple.com/us/app/ramakoti/id1566105925#?platform=iphone';
        } else if(isAndroid) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.pramanam.ramakoti';
        } else 
        return <div> This content is only available on Android and IOS</div>
    }
    return <div className = 'appdownload'> This content is only available on mobile </div>
}
export default appdownload;