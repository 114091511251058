import React, { useEffect, useState ,componentDidMount} from 'react';
import properties from '../properties';
import 'bootstrap/dist/css/bootstrap.min.css';

import axios from 'axios';



function GlobalDashboard() {
         const [globalScore, setGlobalScore] = useState([]);
         const [registedUser, setRegistedUser] = useState([]);
         const MINUTE_MS = 1000; 
         
         const Callajax = () => {
            axios.get(properties.serverUrl + properties.getglobalscore, {
                headers:  { "Access-Control-Allow-Origin": properties.serverUrl }
            }).then(function (data) {
               // console.log("calling ajax");
               // console.log(data.data[0].score);
                setGlobalScore(data.data[0].score.globalScore);
                setRegistedUser(data.data[0].score.registedUser); 
            })
         };
//update timelyscore
useEffect(() => {
    
  {document.body.style.backgroundAttachment =null;   
}

const interval = setInterval(() => {
    //console.log('Logs every minute');
    Callajax();
    }, MINUTE_MS);
return () => clearInterval(interval); 
}, [])


//first time load intial value.
Callajax();
return (   
    
    <div className="GlobalContainer"  >
  <centre style = {{width:'350px'}} >
      <div >
      <h6 className="" style = {{textAlign:'center'}} > Global Rama Koti Count</h6>
      <h1 className="" style = {{textAlign:'center',fontSize:'3rem'}} id="globalScoreId"><b>{(globalScore)}</b></h1>
      </div>
      <div>
      <hr class ="gs_hr"></hr>
      </div>
      <div>
      <h6 className="" style = {{textAlign:'center'}}> Users Contributed</h6>
      <h1 className="" style = {{textAlign:'center',fontSize:'3rem'}}><b>{registedUser}</b></h1>
      </div>
  </centre>
   </div>)
} 
export default GlobalDashboard;

