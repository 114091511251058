import React, { useState } from 'react';
import { Card } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Row, Col } from "react-bootstrap";
import MenuItems from './menuItems';
import BottomMenu from './bottommenu';
import SocialMediaLinks from './socialmedialinks'

function ContactUs() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')

    const handleNameChange = event => {
        setName(event.target.value)
        setEmail(event.target.value)
        setSubject(event.target.value)
        setMessage(event.target.value)
    };
    const handleSubmit = event => {

        const url = "https://ramakoti.pramanam.org/contactus"
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, name, subject, message })
        };
        fetch(url, requestOptions)
            .then(response => console.log('Submitted successfully'))
            .catch(error => console.log('Form submit error', error))
        alert(`Thank you for contacting Pramanam.org. We will contact you shortly.`);
        event.preventDefault();
        event.target.reset();
    };



    return (
        <div class="container" >
            <SocialMediaLinks />
            <MenuItems />
            <div>
                <div class='col-md-10 order-md-2'>
                    <div class='row equal' >
                        <div class='col-md-12'>
                            <h5 className="Sanskritfont title"> Contact</h5>
                            <p style={{ color: "white" }}><b>Want to know more, get connected with us.</b></p>
                        </div>
                    </div>
                    <div class='row' >
                    <div class='col-md-6' >
                            <Card style={{ backgroundColor: 'rgb(230, 84, 0)', color: "white", borderRadius: "10px", fontSize: '12px', padding: '10px', marginTop: '10px', height: '95%' }} >
                                <address><b>LOCATION</b><br></br><br></br>Australia Head Office: <br />  <b> Pramanam Inc.<br></br> Brisbane<br></br>QLD 4000<br></br>Email: support@pramanam.org</b>
                                    <br /><br></br> Bharat Office:<br /><b>PranavaEET Plot 62, Adharsh Nagar Colony, Nagole,<br></br> Hyderabad, TS 500068<br></br>India</b>
                                    <br /><br></br>Email:<br /><b >contact@pranavaeet.com </b>
                                    <br /><br></br>Call:<br /><b>+91 8639443469</b> </address>
                            </Card>
                        </div>
                        <div class='col-md-6'>
                            <Card style={{ backgroundColor: 'rgb(230, 84, 0)', color: "white", fontSize: '12px', borderRadius: "12px", padding: '15px', marginTop: '10px', height: '95%' }} >
                                <form id="contactusform" onSubmit={handleSubmit} >
                                    <div>
                                        <Row style={{ borderRadius: "12px" }}>
                                            <Col>
                                                <Form.Group controlId="formBasicSubject">
                                                    <Form.Label> Your Name </Form.Label>
                                                    <Form.Control type="name" placeholder="Enter name" size="sm" onChange={handleNameChange}  required />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group controlId="formBasicSubject">
                                                    <Form.Label> Your Email  </Form.Label>
                                                    <Form.Control type="email"  placeholder="Enter Email" size="sm" onChange={handleNameChange} required />
                                                </Form.Group>
                                            </Col>

                                            <br />
                                            <Form.Group controlId="formBasicSubject">
                                                <Form.Label> Subject  </Form.Label>
                                                <Form.Control type="text" size="sm" onChange={handleNameChange} required />
                                            </Form.Group>
                                            <br />
                                            <br />
                                            <Form.Group controlId="formBasicMessage">
                                                <Form.Label>Message</Form.Label>
                                                <Form.Control as="textarea" rows={3} size="sm" onChange={handleNameChange} required />
                                            </Form.Group>
                                            <br />
                                        </Row>

                                        <br />
                                    </div>
                                    <button type="submit" class="btn btn-outline-primary rounded-pill" style={{ color: 'rgb(230, 84, 0)', fontSize: '12px', backgroundColor: "white", borderColor: "white", fontWeight: "800" }} >Submit</button>
                                </form>
                            </Card>
                        </div>
                    </div>
                </div>
                <div>
                <BottomMenu page="contactus" />
                </div>
            </div>
        </div>

    )
}
export default ContactUs;