import React, { useState } from 'react';
import { Link } from "react-router-dom";

const MenuItems = () => {
    const [show, setShow] = useState(false)
    return(
         <div class="row">
        <div class="fixed-top " >
        <button class="fa fa-bars menu-bars" style={{marginLeft:'17px',backgroundColor:'transparent',border:'none',fontSize:'25px'}} onClick={() => setShow(!show)}></button>
        <Link to='/' style={{ textDecoration: 'none' }}>
        <h1 className="overlay" style={{ color: 'yellow' }}>  Pramanam</h1></Link>
       {show &&
          <div className="sideitems">
            <ul class="list-none" >
              <Link to='/' style={{ textDecoration: 'none' }}>
                <li class="side-menu-option">Home <i class="fa fa-plus"></i></li></Link>
              <Link to='/About' style={{ textDecoration: 'none' }}>
                <li class="side-menu-option">About<i class="fa fa-plus"></i></li>
              </Link>
              <Link to='/projects' style={{ textDecoration: 'none' }}>
                <li class="side-menu-option">Projects<i class="fa fa-plus"></i></li>
              </Link >
              <Link to='/Opportunities' style={{ textDecoration: 'none' }}>
              <li class="side-menu-option" >Opportunities<i class="fa fa-plus"></i></li>
              </Link>
              <Link to='/ContactUs'style={{ textDecoration: 'none' }}>
                <li class="side-menu-option">Contact Us<i class="fa fa-plus"></i></li>
              </Link>
             
            </ul>
          </div>
          
        }
  
      </div>
      </div>
      
    );
}
export default MenuItems;
