import React from 'react';
import MenuItems from './menuItems';

const Demo = () => {


    return (
        <div class="container">


            <div class="row  justify-content-md-center align-middle scrollbar-hidden" style={{height: '650px',overflowY:'scroll',textAlign:'center' ,background: 'transparent'}}  >
                <div class="col-md-auto " >

                <h3 class="Sanskritfont title" style={{ textAlign: 'center', margin: '50px 12px 14px 95px' }}> 
TERMS AND CONDITIONS </h3>
                    <p className="subTex" style={{ color: '#ffffff' }} >1. We respect your privacy<br></br>
           
           (a) Pramanam respects your right to privacy and is committed to safeguarding the privacy
           of our customers and website visitors. We adhere to the Australian Privacy
           Principles contained in the Privacy Act 1988 (Cth). This policy sets out how we
           collect and treat your personal information.<br></br>
         
           (b) "Personal information" is information we hold which is identifiable as being about
           you.<br></br><br></br>
       
           2. Collection of personal information<br></br>
        
           (a) Pramanam will, from time to time, receive and store personal information you enter
           onto our website, provided to us directly or given to us in other forms.<br></br>
          
           (b) You may provide
           basic information such as your name, phone number, address and email address to
           enable us to send information, provide updates and process your product or service order.
           We may collect additional information at other times, including but not limited to, when you
           provide feedback, when you provide information about your personal or business affairs,
           change your content or email preference, respond to surveys and/or promotions, provide
           financial or credit card information, or communicate with our customer support.<br></br>
           
           (c) Additionally, we may also collect any other information you provide while
           interacting with us.<br></br><br></br>
          
           3. How we collect your personal information<br></br>
​
           (a) Pramanam collects personal information from you in a variety of ways, including when
           you interact with us electronically or in person, when you access our website and when we
           provide our services to you. We may receive personal information from third parties. If we
           do, we will protect it as set out in this Privacy Policy.<br></br><br></br>
           
           4. Use of your personal information<br></br>
           
           (a) Pramanam may use personal information collected from you to provide you with
           information, updates and our services. We may also make you aware of new and
           additional products, services and opportunities available to you. We may use your
           personal information to improve our products and services and better understand
           your needs.<br></br>
           
           (b) Pramanam may contact you by a variety of measures including, but not limited totelephone, email, sms
           or mail.<br></br><br></br>
            5. Disclosure of your personal information<br></br>
          
           (a) We may disclose your personal information to any of our employees, officers,
           insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably
           necessary for the purposes set out in this Policy. Personal information is only supplied to
           a third party when it is required for the delivery of our services.<br></br>
           
           (b) We may from time to time need to disclose personal information to comply with a legal
           requirement, such as a law, regulation, court order, subpoena, warrant, in the course of a
           legal proceeding or in response to a law enforcement agency request.<br></br>
           
           (c) We may also use your personal information to protect the copyright, trademarks, legal
           rights, property or safety of Pramanam, pramanam.org, its customers or third
           parties.<br></br>
           
           (d) Information that we collect may from time to time be stored, processed in or
           transferred between parties located in countries outside of Australia.<br></br>
           
           (e) If there is a change of control in our business or a sale or transfer of business assets,
           we reserve the right to transfer to the extent permissible at law our user
           databases, together with any personal information and non-personal information
           contained in those databases. This information may be disclosed to a potential
           purchaser under an agreement to maintain confidentiality. We would seek to only
           disclose information in good faith and where required by any of the above
           circumstances.<br></br>
           
           (f) By providing us with personal information, you consent to the terms of this Privacy
           Policy and the types of disclosure covered by this Policy. Where we disclose your
           personal information to third parties, we will request that the third party follow this
           Policy regarding handling your personal information.<br></br><br></br>
           
         6. Security of your personal information<br></br>
           
           (a) Pramanam is committed to ensuring that the information you provide to us is secure. In
           order to prevent unauthorised access or disclosure, we have put in place suitable
           physical, electronic and managerial procedures to safeguard and secure information
           and protect it from misuse, interference, loss and unauthorised access, modification
           and disclosure.<br></br>
           
           (b) The transmission and exchange of information is carried out at your own risk. We
           cannot guarantee the security of any information that you transmit to us, or receive from
           us. Although we take measures to safeguard against unauthorised disclosures ofinformation, we cannot
           assure you that personal information that we
           collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.<br></br><br></br>
           
         7. Access to your personal information <br></br>
           
           (a) You may request details of personal information that we hold about you in
           accordance with the provisions of the Privacy Act 1988 (Cth). A small
           administrative fee may be payable for the provision of information. If you would
           like a copy of the information, which we hold about you or believe that any
           information we hold on you is inaccurate, out of date, incomplete, irrelevant or
           misleading, please email us at ramayanam.pramanam@gmail.com.<br></br>
           
           (b) We reserve the right to refuse to provide you with information that we hold about you,
           in certain circumstances set out in the Privacy Act.<br></br><br></br>
           
           8. Complaints about privacy<br></br>
           
           (a) If you have any complaints about our privacy practises, please feel free to send in
           details of your complaints to 6 Scholard Cres, Bridgeman Downs, Queensland,
           4035. We take complaints very seriously and will respond shortly after receiving
           written notice of your complaint.<br></br><br></br>
           
          9. Changes to Privacy Policy<br></br>
           
           (a) Please be aware that we may change this Privacy Policy in the future. We may
           modify this Policy at any time, in our sole discretion and all modifications will be
           effective immediately upon our posting of the modifications on our website or notice
           board. Please check back from time to time to review our Privacy Policy.<br></br><br></br>
           
        10. Website<br></br>
           
           (a) When you visit our website
           When you come to our website (pramanam.org) we may collect certain
           information such as browser type, operating system, website visited immediately
           before coming to our site, etc. This information is used in an aggregated manner
           to analyse how people use our site, such that we can improve our service.<br></br>
          
           (b) Cookies
           We may from time to time use cookies on our website. Cookies are very small files
           which a website uses to identify you when you come back to the site and to store
           details about your use of the site. Cookies are not malicious programs that accessor damage your computer.
           Most web browsers automatically accept cookies but you
           can choose to reject cookies by changing your browser settings. However, this
           may prevent you from taking full advantage of our website. Our website may from
           time to time use cookies to analyses website traffic and help us provide a better
           website visitor experience. In addition, cookies may be used to serve relevant ads
           to website visitors through third party services such as Google Adwords. These ads
           may appear on this website or other websites you visit.<br></br>
          
           (c) Third party sites
           Our site may from time to time have links to other websites not owned or
           controlled by us. These links are meant for your convenience only. Links to third
           party websites do not constitute sponsorship or endorsement or approval of these
           websites. Please be aware that Pramanam is not responsible for the privacy
           practises of other such websites. We encourage our users to be aware, when they
           leave our website, to read the privacy statements of each and every website that
           collects personal identifiable information.<br></br><br></br>
          
           Approved by<br></br>
           Signature<br></br>
           ....................................................................................................................<br></br>
           Full Name: Durga Karra<br></br>
           Date: 25th February 2021<br></br>
           ''
            </p>
                   
                </div>
            </div>

            <div class="icon-bar">
                <a href="https://www.facebook.com/" className="facebook"> <i className="fa fa-facebook-square" style={{ color: '#ffffff' }}></i></a>

                <a href="https://www.twitter.com/" className="twitter" >  <i className="fa fa-twitter-square" style={{ color: '#ffffff' }}>
                </i>  </a>

                <a href="https://www.linkedin.com/" className="linkedin" > <i className="fa fa-linkedin-square" style={{ color: '#ffffff' }}>
                </i>  </a>

                <a href="https://www.gmail.com/" className="gmail" > <i className="fa fa-google" style={{ color: '#ffffff' }}> </i></a>
            </div>

            <MenuItems />

        </div>

    );
}
export default Demo;