import React from 'react';
import { Link } from "react-router-dom";

const Getconnected = () => {
    return (
<div class="row">
  <div col-s-12>
<p className="subTex" style={{ color: '#ffffff'}} >Want to know more? &nbsp;&nbsp;</p>
<Link to='/ContactUs' style={{ textDecoration: 'none' }}>
<button type="button" class="btn btn-outline-primary rounded-pill" style={{ color: 'rgb(230, 84, 0)', fontSize: '12px',backgroundColor:"white",borderColor:"white",fontWeight:"800" }} >GET CONNECTED WITH US</button></Link>
</div>
</div>
  )
}
export default Getconnected;