import React, { useEffect, useState } from 'react';
import MenuItems from './menuItems'
import Getconnected from './getconnected';
import BottomMenu from './bottommenu';
import properties from '../properties';
import Parser from 'html-react-parser';
import axios from 'axios';
const renderHTML = (rawHTML: "") => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

const AboutCard = () => {
    const [aboutData, setaboutData] = useState([]);
    useEffect(() => {
        axios.get(properties.serverUrl + properties.about, {
            headers:
                { "Access-Control-Allow-Origin": properties.serverUrl }

        }).then(function (data) {
            console.log(data)
            setaboutData(data.data);
        })

    }, []);

    return (
        <div class="container" style={{ marginTop: '0px', paddingLeft: '45px' }}>

            <div class="row " >
                {aboutData.map((val, key) => {
                    return <div>

                        <div>

                            <h3 class="Sanskritfont title"  > dharmo rakshati rakshitah </h3>
                            <div class="aboutustext">{renderHTML(Parser(val.content))}</div> 
                        </div>
                    </div>
                })}
            </div>
            <Getconnected />
            <div class="icon-bar">
                <a href="https://www.facebook.com/" className="facebook"> <i className="fa fa-facebook-square" style={{ color: '#FFFFFF' }}></i></a>
                <a href="https://www.twitter.com/" className="twitter" >  <i className="fa fa-twitter-square" style={{ color: '#FFFFFF' }}>
                </i>  </a>
                <a href="https://www.linkedin.com/" className="linkedin" > <i className="fa fa-linkedin-square" style={{ color: '#FFFFFF' }}>
                </i>  </a>
                <a href="https://www.gmail.com/" className="gmail" > <i className="fa fa-google" style={{ color: '#FFFFFF' }}> </i></a>
            </div>
            <div  >
                <MenuItems />
            </div>
            <div class="projects-page card-collection row">
                <div class="col-md-3 order-md-2">

                </div>



            </div>
            <BottomMenu page="aboutus" />
        </div>
    );
}
export default AboutCard;