import React from 'react';
import { Link } from "react-router-dom";
var colorCounter = 0;
var colors = [ '#822102', '#4C1300', '#150500'];
var bottomItems = [{paramName:'project',linkText:"PROJECTS",linkurl:"Projects"},{paramName:'aboutus',linkText:"ABOUT PRAMANAM",linkurl:"About"},{paramName:'opportunities',linkText:"OPPORTUNITIES",linkurl:"Opportunities"},{paramName:'contactus',linkText:"LOCATE OUR OFFICE",linkurl:"ContactUs"}]
   const getColorStyle = () => {
    const colorStyle = {
        backgroundColor:  colors[colorCounter],
        height: '50px',
    };
    colorCounter = colorCounter + 1
    return colorStyle;
   }
   const getOrderDetails = () => {
       return 'col-md-3 order-md-'+(colorCounter+3);
   }

const BottomMenu = (props) => {
    colorCounter = 0;
    return (
        <div class="projects-page card-collection row">
                <div class="col-md-2 order-md-2"> </div> 
                {bottomItems.map((singleItem) => (
                    props.page !== singleItem.paramName &&
                    <div class={getOrderDetails()} style={{ paddingLeft: 0, paddingRight: 0, marginRight: 0, marginLeft: 0 }}>
                    <div class="card h-60" style={getColorStyle()}>
                        <div class="card-body">
                            <Link to={"/"+singleItem.linkurl} style={{ textDecoration: 'none' }}>
                                <h6 class="card-title " style={{ fontWeight: 'bold', color: '#FFFFFF' }}> {singleItem.linkText}</h6>
                            </Link>
                            <i class="fa fa-plus  float-right" style={{ color: '#FFFFFF' }} ></i>
                        </div>
                    </div>
                </div>
                ))}
                <div class="col-md-1 order-md-1 ">
                    <div class="terms-policy" >
                        <a style={{ color: '#E65400' }} href="/termsncondtions">Terms and Conditions</a> <br />
                        <a style={{ color: '#E65400' }} href="/privacypolicy">Privacy Policy </a> <br />
                        <div style={{ fontSize: '7px', color: '#E65400' }}>Powered By <a style={{ color: '#E65400' }} href="http://www.pranavaeet.com/">PranavaEET</a></div>
                    </div>
                </div>
             
                
            </div>
            
  )
}
export default BottomMenu;