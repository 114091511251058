const properties = {
   //serverUrl:'http://10.0.0.21:5002',
   serverUrl:'https://ramakoti.pramanam.org/',
   //serverUrl:'https://rk.pranavaeet.com',
   project: '/getprojects',
    termsCondition: '/termsncondtions',
    privacy: '/privacypolicy',
    opportunities:'/getopportunities',
    contactUs:'/contactus',
    about:'/getaboutpramanam',
    getglobalscore:'/getglobalscore',
    download:'/downloadapp'
    }
    export default properties;
   
    