import React, { useEffect, useState } from 'react';

// import { Carousel } from 'react-responsive-carousel';
import MenuItems from './menuItems'
import Img from './ProjectImages/ramakoti.png'
import applestoreicon from './ProjectImages/Appstoredownloadtransparent.png'
import googleplayicon from './ProjectImages/googleplayicon.png'
import Carousel from "react-elastic-carousel";
import axios from 'axios';
import properties from '../properties';
import Parser from 'html-react-parser';
import Getconnected from './getconnected';
import BottomMenu from './bottommenu';
import SocialMediaLinks from './socialmedialinks'
import { } from "react-bootstrap";

const Projects = () => {
    const [userData, setUserData] = useState([]);
    useEffect(() => {
        axios.get(properties.serverUrl + properties.project, {
            headers:
                { "Access-Control-Allow-Origin": properties.serverUrl }

        }).then(function (data) {
            console.log(data)
            setUserData(data.data);
        })
        

    }, []);
 
    return (
        <div class="container " >
            <div style={{ paddingLeft: '5px' }}>
                <div class="row  justify-content" >
                    <div class="col-md-auto" >
                        <h3 class="Sanskritfont title" > Projects </h3>
                        <h6 style={{ textAlign: 'left', color: 'white' }}> Explore our droplets endeavours to connect ourselves to sanathana Dharma </h6>
                    </div>
                </div>
                <div class="col-md-12">
                <Carousel  autoPlay="true" interval="6000" transitionTime="300" infiniteLoop="true" >
                        {userData.map(function (val, i) {
                            return <item>
                                
                                <div style={{ backgroundColor: '#E65400' }}>
                                    <div class="row">
                                        <div class="col-lg-12 ">
                                            <div  style={{backgroundColor: '#E65400'}}>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <b class="Sanskritfont " style={{ color: '#ffffff', fontSize: '30px' }}>
                                                            <img src={Img} style={{ width: '50px', height: '50px',fontSize:'12px' }} alt='Logo' />
                                                            {Parser(val.title)} </b>
                                                    </div>
                                                    <div class="col-md-2 ">
                                                        <h9 style={{ color: 'yellow', fontSize: '12px', textAlign: "right" }}> Download the Ramakoti App </h9>
                                                    </div>
                                                    <div class="col-md-4 text-center">
                                                        <a href="https://play.google.com/store/apps/details?id=com.pramanam.ramakoti">
                                                            <img src={googleplayicon} className="" style={{ height: '40px', width: 'auto' }} alt='Google Android Link'
                                                            /></a>&nbsp;<a href="https://apps.apple.com/us/app/ramakoti/id1566105925#?platform=iphone">
                                                            <img src={applestoreicon} style={{ height: '40px', width: 'auto' }} alt='App Store Link'
                                                            /> 
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                   <div class="scrollbar-hidden height-fix-1 " style={{ color: '#ffffff', fontSize: '12px', padding:'10px',wordBreak:'break-all',height:'50vh'}}>{Parser(val.content)}</div>
                                   
                                </div>
                            </item>
                        })}
                    </Carousel>
                </div>
               
            </div>
            
            
            
            
            
                <Getconnected />
                <SocialMediaLinks />
                
            <div>
                <MenuItems />
            </div>
            <BottomMenu page="project" />
        </div>
    );
}
export default Projects;

