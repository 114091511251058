import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import MenuItems from './menuItems';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Getconnected from './getconnected';
import Parser from 'html-react-parser';
import axios from 'axios';
import properties from '../properties';
const renderHTML = (rawHTML: String) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });


const Home = () => {

    const [userData, setUserData] = useState([]);
    const [opportunityData, setopportunityData] = useState([]);
    const [aboutData, setaboutData] = useState([]);
    useEffect(() => {
        axios.get(properties.serverUrl + properties.project, {
            headers:
                { "Access-Control-Allow-Origin": properties.serverUrl }

        }).then(function (data) {
            console.log(data)
            setUserData(data.data);
        })

    }, []);
    useEffect(() => {
        axios.get(properties.serverUrl + properties.opportunities, {
            headers:
                { "Access-Control-Allow-Origin": properties.serverUrl }

        }).then(function (data) {
            console.log(data)
            setopportunityData(data.data);
        })

    }, []);
    useEffect(() => {
        axios.get(properties.serverUrl + properties.about, {
            headers:
                { "Access-Control-Allow-Origin": properties.serverUrl }

        }).then(function (data) {
            console.log(data)
            setaboutData(data.data);
        })

    }, []);
    return (
        <div class="container">
            <div class="row  justify-content-md-center align-middle" >
                <div class="col-md-auto " >
                    <h3 class="row-heading " style={{ paddingTop: '200px' }}> dharmo rakshati rakshitah </h3>
                    <Getconnected />
                </div>
            </div>
            <div class="icon-bar">
                <a href="https://www.facebook.com/" className="facebook"> <i className="fa fa-facebook-square" style={{ color: '#ffffff' }}>
                </i> </a>
                <a href="https://www.twitter.com/" className="twitter" >  <i className="fa fa-twitter-square" style={{ color: '#ffffff' }}>
                </i>  </a>
                <a href="https://www.linkedin.com/" className="linkedin" > <i className="fa fa-linkedin-square" style={{ color: '#ffffff' }}>
                </i>  </a>
                <a href="https://www.gmail.com/" className="gmail" > <i className="fa fa-google" style={{ color: '#ffffff' }}> </i></a>
            </div>
            <MenuItems />
            <div class="card-collection row">
                <div class="col-md-3 order-md-2" style={{ paddingLeft: 0, paddingRight: 0, marginRight: 0, marginLeft: 0 }}>
                    <div class="card h-60" style={{ backgroundColor: '#E65400' }}>
                        <Link style={{ textDecoration: 'none' }} to='/About'>
                            <div class="card-body">
                                <h6 class="card-title" style={{ textAlign:'justify',fontWeight: 'bold', color: '#ffffff',fontSize: '15px' }} >ABOUT PRAMANAM</h6>
                                {aboutData.map((val, key) => {
                                    return <div>
                                     <div class='projects' style={{ height: '110px',overflow:'hidden',whitespace:'nowrap',textoverflow:'ellipsis', color: 'white', fontSize: '12px' }}>
                                             {renderHTML(Parser(val.content))}
                                            </div>
                                    </div>
                                })}
                            </div>
                        </Link>
                    </div>
                </div>
                <div class="col-md-3 order-md-3" style={{ paddingLeft: 0, paddingRight: 0, marginRight: 0, marginLeft: 0 }}>
                    <div class="card h-60" style={{ backgroundColor: '#822102' }}>
                        <Link style={{ textDecoration: 'none' }} to='/projects'>
                            <div class="card-body">
                                <h6 class="card-title " style={{ fontWeight: 'bold', color: '#ffffff' }}> PROJECTS </h6>
                                <Carousel autoPlay="true" interval="3000" transitionTime="300" infiniteLoop="true">

                                    {userData.map(function (val, i) {
                                        return <item>
                                            <div>   <b class="Sanskritfont " style={{ color: 'yellow', fontSize: '25px',textAlign:'left' }}>
                                            {Parser(val.title)} </b></div>
                                            <div class='cardContentstyle' >
                                             {Parser(val.content)}
                                            </div>
                                        </item>
                                    })}
                                </Carousel>
                                <i class="fa fa-plus  float-right" style={{ color: '#ffffff' }} ></i>
                            </div>
                        </Link>
                    </div>
                </div>
                <div class="col-md-3 order-md-4" style={{ paddingLeft: 0, paddingRight: 0, marginRight: 0, marginLeft: 0 }}>
                    <div class="card  h-60" style={{ backgroundColor: '#4C1300' }}>
                        <Link style={{ textDecoration: 'none' }} to='/Opportunities'>
                            <div class="card-body">
                                <h6 class="card-title" style={{ fontWeight: 'bold', color: '#ffffff' }}> OPPORTUNITIES </h6>
                                <Carousel autoPlay="true" interval="3000" transitionTime="300" infiniteLoop="true">

                                    {opportunityData.map(function (val, i) {
                                    //    <TrimText></TrimText>
                                        return  <item>
                                            
                                            <div>   <b class="Sanskritfont " style={{ color: 'yellow', fontSize: '25px',textAlign:'left' }}>
                                            {Parser(val.title)} </b></div>
                                            <div class='cardContentstyle' >
                                             {Parser(val.content)}
                                            </div>
                                        </item>
                                    })}
                                </Carousel>
                                <br />
                                <i class="fa fa-plus  float-right" style={{ color: '#ffffff' }} ></i>
                            </div>
                        </Link>
                    </div>
                </div>
                <div class="col-md-2 order-md-5" style={{ paddingLeft: 0, paddingRight: 0, marginRight: 0, marginLeft: 0 }}>
                    <div class="card  h-60" style={{ backgroundColor: '#150500' }} >
                        <Link style={{ textDecoration: 'none' }} to='/ContactUs'>
                            <div class="card-body">
                                <h6 class="card-title" style={{ fontWeight: 'bold', color: '#ffffff' }} >LOCATE OUR OFFICE</h6>
                                <b class="card-text Textcolor">RamaKoti</b><p class="card-text Textcolor" style={{ textAlign: 'justify' }}>Nagole,Hyderabad,Telengana,India</p><p style={{ fontSize: '12px', color: '#E65400' }}>phone:+91 8639443469 <br />email:contact@pranavaeet.com </p>
                                <i class="fa fa-plus  float-right" style={{ color: '#ffffff' }} ></i>
                            </div>
                        </Link>
                    </div>
                </div>
                <div class="col-md-1 order-md-1 " >
                    <div class="terms-policy">
                        <a style={{ color: '#E65400' }} href="/termsncondtions">Terms and Conditions </a> <br />
                        <a style={{ color: '#E65400' }} href="/privacypolicy">Privacy Policy </a> <br />
                        <div style={{ fontSize: '7px', color: '#E65400' }}>Powered By <a style={{ color: '#E65400' }} href="http://www.pranavaeet.com/">PranavaEET</a></div>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default Home;

