import React from 'react';
const SocialMediaLinks = () => {
    return (
        <div class="icon-bar">
        <a href="https://www.facebook.com/" className="facebook"> <i className="fa fa-facebook-square" style={{ color: '#ffffff' }}></i></a>

        <a href="https://www.twitter.com/" className="twitter" >  <i className="fa fa-twitter-square" style={{ color: '#ffffff' }}>
        </i>  </a>

        <a href="https://www.linkedin.com/" className="linkedin" > <i className="fa fa-linkedin-square" style={{ color: '#ffffff' }}>
        </i>  </a>

        <a href="https://www.gmail.com/" className="gmail" > <i className="fa fa-google" style={{ color: '#ffffff' }}> </i></a>
    </div>
  )
}
export default SocialMediaLinks;